import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { authenticationService } from '../../auth/authenticationService';
import { configurationService } from '../../configsServices/appConfigService';
import { mapUserDto } from '../../mapping/UserMapping';
import { authenticatedUser, globalLoadingState } from '../../store/recoilStore';
import { UserRoles } from '../../types/UserTypes';
let activeRequestsCount = 0;

export const useApi = (disableGlobalLoadingIndicator?: boolean) => {
    const navigate = useNavigate();
    const setGlobalLoadingState = useSetRecoilState(globalLoadingState);
    const setCurrentUser = useSetRecoilState(authenticatedUser);
    const startLoading = () => {
        if (!disableGlobalLoadingIndicator) {
            activeRequestsCount++;
            setGlobalLoadingState(true);
        }
    };
    const stopLoading = () => {
        if (!disableGlobalLoadingIndicator) {
            activeRequestsCount--;
            setGlobalLoadingState(!!activeRequestsCount);
        }
    };

    const apiInstance = useMemo(() => {
        const instance = axios.create({
            baseURL: configurationService.configuration.apiPath,
        });

        instance.interceptors.request.use(
            async (config) => {
                startLoading();
                const accessToken = (await authenticationService.getAccessToken())?.accessToken;
                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => {
                stopLoading();
                return Promise.reject(error);
            },
        );

        instance.interceptors.response.use(
            (response) => {
                stopLoading();
                return response;
            },
            async (error) => {
                stopLoading();

                if (error.response?.status === StatusCodes.UNAUTHORIZED) {
                    error.isSilent = true;
                    navigate('/unauthorized');
                }

                if (error.response?.status === StatusCodes.FORBIDDEN) {
                    error.isSilent = true;

                    const user = await getUser();

                    if (user?.role === UserRoles.InternalReminderOnly) {
                        navigate('/forbidden-dcm-core');
                    } else if (user) {
                        setCurrentUser(user);
                        if (error.response.url !== 'creditor-groups/accessible-creditors')
                            navigate('/forbidden');
                    } else {
                        navigate('/unauthorized');
                    }
                }
                
                return Promise.reject(error);
            },
        );

        return instance;
    }, []);

    return apiInstance;
};

async function getUser() {
    try {
        const accessToken = (await authenticationService.getAccessToken())?.accessToken;
        const response = await fetch(`${configurationService.configuration.apiPath}/auth/user/`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (!response.ok) throw new Error();
        else return mapUserDto(await response.json());
    } catch (error) {
        console.log('User refetch after forbidden response failed');
    }
}

export const usePublicApi = () =>
    axios.create({
        baseURL: configurationService.configuration.apiPath,
    });

export const headerValues = { appJson: { 'Content-Type': 'application/json' } };
