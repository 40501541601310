import { StatusCodes } from 'http-status-codes';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { ApplicationConstants } from '../../constants/ApplicationConstants';
import { accessibleCreditorsState, selectedCreditorState } from '../../store/recoilStore';
import { AccessibleCreditorDto } from '../../types/CreditorTypes';
import { useApi } from './useApi';

export function useAccessibleCreditorsService() {
    const api = useApi();
    const setSelectedCreditor = useSetRecoilState(selectedCreditorState);
    const setAccessibleCreditors = useSetRecoilState(accessibleCreditorsState);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    async function updateAccessibleCreditorsState(): Promise<number | null> {
        try {
            const resp = await api.get<AccessibleCreditorDto[]>(
                'creditor-groups/accessible-creditors',
            );

            if (resp.status !== StatusCodes.OK) throw new Error();

            const accessibleCreditorDtos = resp.data;
            setAccessibleCreditors(accessibleCreditorDtos);

            if (accessibleCreditorDtos.length === 0) {
                setSelectedCreditor(null);
                localStorage.removeItem(ApplicationConstants.DcmCreditorId);
                return null;
            } else {
                const dcmCreditorIdValue = localStorage.getItem(ApplicationConstants.DcmCreditorId);
                let currentCreditorId: number | null = Number(dcmCreditorIdValue) || null;

                if (
                    !currentCreditorId ||
                    !accessibleCreditorDtos.some((x) => x.Id === currentCreditorId)
                )
                    currentCreditorId = accessibleCreditorDtos[0].Id;

                setSelectedCreditor(
                    accessibleCreditorDtos?.find((x) => x.Id === currentCreditorId) ?? null,
                );
                localStorage.setItem(
                    ApplicationConstants.DcmCreditorId,
                    currentCreditorId.toString(),
                );
                return currentCreditorId;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            !error.isSilent &&
                enqueueSnackbar(t('errorMessages.creditorsFetch'), {
                    variant: 'errorSnackbar',
                });
        }
        return null;
    }

    return { updateAccessibleCreditorsState };
}
